import api from "../axios/ChatBotApi";
import * as T from "../types/types.chatbot";

export const requestChatbot = async (
  cancerApi: string,
  data: T.CancerDataType,
) => {
  const response = await api.post(`${cancerApi}`, data);
  return response.data;
};
